<template>
    <div></div>
</template>

<script>

export default ({
    async mounted() {
        if(!this.$auth.user){
            this.$router.push('/login');
            return;
        }
        const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
        });
        const token = await this.devDocsToken()
        console.log(token)
        const url = token.replace('brime.readme.io/',`brime.readme.io${params.redirect}`)
        window.location.replace(url)
    },
})
</script>
